<template>
  <div>
    <ed-empresa
      :intCadastroGeralId="$route.params.intId"
      :intTipoCadastroGeralId="1339"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdEmpresa from "@/components/cadastro/geral/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdEmpresa },
  mounted() {
    if (!this.$route.params.intId) {
      this.$router.push({ name: "cadastro.fornecedor.register" });
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
